export const SUCCESS_CODE = '00000'

export const rewardStatus = {
   '1': '待发放',
   '2': '已发放',
   '3': '已退单',
   '4': '已冻结',
   '5': '空号不发',
   '6': '未激活不发',
   '9': '扣减成功',
   '13': '省钱购不发',
   '14': '待发放',
   '20': '已取消',
   '21': '未达标不发'
}

export const beanStatus = {
   '1': '待发放',
   '2': '已发放',
   '3': '已退单',
   '4': '已冻结',
   '5': '空号不发',
   '6': '未激活不发',
   '9': '扣减成功',
   '14': '待发放',
   '20': '已取消',
   '21': '未达标不发'
}

//购买方式
export const buyMethods = {
   '0':'普通自购',
   '-1':'激活自购',
   '1':'推广购买',
   '-2': '省钱购'
}

export const buyMethodsNew = {
   '0':'普通自购',
   '1':'推广购买',
   '-2': '省钱购'
}