<template>
    <a-card :bordered="false">
      <a-row class="query-row" type="flex" :gutter="12">
        <a-col style="width: 220px">
          <a-input
            v-model.trim="queryData.name"
            allowClear
            placeholder="请输入企业名称"
          ></a-input>
        </a-col>
        <a-col style="width: 220px">
          <a-input
            v-model.trim="queryData.taxCode"
            allowClear
            placeholder="请输入企业税号"
          ></a-input>
        </a-col>
        <a-col style="width: 220px">
          <a-input
            v-model.trim="queryData.mobile"
            allowClear
            placeholder="请输入推荐人手机号"
          ></a-input>
        </a-col>
        <a-col style="width: 230px">
          <a-select style="width: 220px" v-model="queryData.type" allowClear placeholder="点击选择企业类型">
            <a-select-option v-for="item in enterpriseTypeOptions" :value="Number(item.value)" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-col>
        <a-col style="width: 230px">
          <a-select style="width: 220px" v-model="queryData.status" allowClear placeholder="点击选择状态">
            <a-select-option v-for="item in statusOptions" :value="Number(item.value)" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-col>
  
        <a-col>
          <a-button @click="search" type="primary" style="margin-right: 12px">
            查询
          </a-button>
        </a-col>
      </a-row>
  
      <a-table
        :columns="columns"
        :dataSource="tableData"
        :pagination="pagination"
        :rowKey="(record) => record.mobile"
        :loading="loading"
        :scroll="{x: true}"
        size="default"
        @change="handleTableChange"
      >
        <div slot="statusStr" slot-scope="text">
          <span v-if="text == '已认证'" style="color:#ff6602">{{ text }}</span>
          <span v-else>{{ text }}</span>
        </div>

        <div class="show-img" slot="img" slot-scope="text, record">
          <div v-if="record && record.img" style="margin-right:5px;">
            <img :src="record.img" @click="handlePreview(record.img)"/>
          </div>
        </div>
      
        <div slot="action" slot-scope="text, record">
          <a v-if="record && ['待审核'].includes(record.statusStr)" @click="showEditModal(record,1)">审核</a>
          <a-divider type="vertical" v-if="record && ['待审核'].includes(record.statusStr)"/>
          <a @click="showEditModal(record,2)">记录</a>
        </div>
      </a-table>
       
      <!-- 审核操作 -->
      <a-modal
        v-model="editModalVisible"
        title="企业账号认证审核"
        :confirm-loading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        width="500px"
        :maskClosable="false"
        :mask="true"
      >
        <a-form-model ref="baseForm" layout='horizontal' :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
          <a-form-model-item label="审核状态" prop="status">
            <a-radio-group v-model="form.status" @change="onChangeRadio">
              <a-radio value="2">
                审核通过
              </a-radio>
              <a-radio value="-1">
                驳回处理
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
  
          <a-form-model-item label="驳回原因" prop="reason">
            <a-textarea :disabled="textDisabled"  v-model="form.reason" placeholder="请输入驳回原因" :autoSize='{minRows: 3}' allowClear />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 审核记录 -->
      <a-modal width="1000px" v-drag-modal :destroyOnClose='true' v-model="detailModalVisible" :footer='null' title='审核记录' >
         <a-table :columns="detailColumns" :dataSource="detailTableData" :pagination="false" size="default">
          <template slot="reason" slot-scope="text, record">
            <div v-if="record.reason" style="white-space: pre-line;">
              {{ record.reason }}
            </div>
            <span v-else>/</span>
          </template>
         </a-table>
      </a-modal>
      
      <!-- 图片预览 -->
      <a-modal width="800px" :visible="previewVisible" title="营业执照" :footer="null" @cancel="previewVisible = false">
        <img style="width: 100%" :src="previewImage" />
      </a-modal>
  
    </a-card>
  </template>
  
  <script>
  import api from '@/api';
  import Cookies from 'js-cookie';
  import { SUCCESS_CODE } from '@/config';
  export default {
    data() {
      return {
        loading: false,
        formLayout: 'horizontal',
        form: {
          radioValue:undefined,
          reason:undefined,
        },
        editModalVisible: false,
        detailModalVisible:false,
        title: '',
        tableData: [],
        pagination: {
          pageSize: 10,
          total: 0,
          current: 1,
          size: 'small',
          pageSizeOptions:['5','10', '20', '50'],
          showLessItems: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: total => `共 ${total} 条`
        },
        queryData: {},
        currentData: {},
        columns: [
          {
            title: '序号',
            dataIndex: 'no',
            align: 'center',
            ellipsis:true,
            customRender: (text, record, index) => {
              return index + 1
            }
          },
          {
            title: "企业名称",
            dataIndex: "name",
            align: "center"
          },
          {
            title: "税号",
            dataIndex: "taxCode",
            align: "center"
          },
          {
            title: "手机号码",
            dataIndex: "enterpriseMobile",
            align: "center"
          },
          {
            title: "企业类型",
            dataIndex: "roleStr",
            align: "center"
          },
          {
            title: "推荐人",
            dataIndex: "recommenderMobile",
            align: "center",
            customRender: text => {
              return text ? text : '/'
            }
          },
          {
            title: "推荐注册码",
            dataIndex: "code",
            align: "center",
            customRender: text => {
              return text ? text : '/'
            }
          },
          {
            title: '营业执照',
            dataIndex: 'img',
            align: 'center',
            scopedSlots: { customRender: 'img' }
          },
          {
            title: "状态",
            dataIndex: "statusStr",
            align: "center",
            scopedSlots: { customRender: 'statusStr' }
          },
          {
            title: "注册时间",
            dataIndex: "createTimeStr",
            align: "center",
          },
          {
            title: "认证时间",
            dataIndex: "certificationTimeStr",
            align: "center",
          },
          {
            title: '操作',
            align: 'center',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
          }
        ],
        detailColumns:[ 
          {
            title: '序号',
            dataIndex: 'no',
            align: 'center',
            ellipsis:true,
            customRender: (text, record, index) => {
              return index + 1
            }
          },
          {
            title: '处理人',
            dataIndex: 'adminId',
            align: 'center',
            width: 130,

          },
          {
            title: '审核状态',
            dataIndex: 'statusStr',
            align: 'center',
            width: 100,
          },
          {
            title: '驳回原因',
            dataIndex: 'reason',
            align: 'center',
            scopedSlots: { customRender: 'reason' }
          },
          {
            title: '处理时间',
            dataIndex: 'createTimeStr',
            align: 'center',
          }
        ],
        textDisabled:true,
        detailTableData:[],
        rules:{
          status:[
            { required: true, message: '请选择审核状态', trigger: 'change' },
          ],
          reason:[
            { required: false, message: '请填写驳回原因', trigger: 'change' },
            { max: 200, message: '字数不能超过200', trigger: "blur" }
          ]
        },
        confirmLoading:false,
        previewImage:"",
        previewVisible:false,
        enterpriseTypeOptions:[
          {value:'3',label:'服务中心'},
          {value:'2',label:'普通企业'},
        ],
        statusOptions:[
          {value:'0',label:'待认证'},
          {value:'1',label:'待审核'},
          {value:'2',label:'已认证'},
          {value:'-1',label:'已驳回'},
        ],
      };
    },
    methods: {
      handleVisible(flag) {
        this.editModalVisible = flag;
        if (!flag) {
          this.currentData = {};
        }
      },
      showEditModal(record,type) {
        this.currentData = record;
        // 1是审核，2是查看审核记录
        if(type == 1){
          this.handleVisible(true);
        }else{
          this.detailTableData = record.logs || [];
          this.detailModalVisible = true;
        }
      },
      onChangeRadio(e){
        let val = e.target.value
        if(val == 2){
          this.textDisabled = true
          this.rules.reason[0].required = false
          this.form.reason = ""
          this.$refs.baseForm.validate()
        }else{
          this.textDisabled = false
          this.rules.reason[0].required = true
        }
      },
      // 提交审核表单
      async editFormData(formData,type) {
        this.confirmLoading = true
        let obj = JSON.parse(JSON.stringify(formData))
        obj.status = Number(obj.status)
        try{
          const { code, msg } = await api.shopkeeperManage.enterpriseAudit({
            ...obj
          });
          this.confirmLoading = false
          if (code === SUCCESS_CODE) {
            this.clearAuditForm();
            this.handleVisible(false);
            this.$message.success(msg);
            this.getTableData();
          } else {
            this.$message.error(msg);
          }
        } catch(e){
          this.confirmLoading = false
        }
        
      },
      clearAuditForm(){
        this.form = {
          radioValue:undefined,
          reason:undefined
        }
        this.$refs.baseForm.resetFields()
      },

      handleSubmit(e) {
        e.preventDefault();
        const vm = this
        const f = vm.fileList
        vm.$refs.baseForm.validate((err,values) => {
          if (err) {
            console.log('Received values of form: ', values);
            vm.editFormData({ ...vm.form, id: vm.currentData.id, adminId:Cookies.get('id') })
          }else{
            // this.$message.error("")
          }
        });
      },
      handleCancel() {
        this.$refs.baseForm.resetFields()
        this.form={
          status: undefined,
          reason: ""
        },
        this.handleVisible(false);
      },
      handleTableChange(pagination) {
        const { current, pageSize } = pagination;
        const param = {
          pageNum: current,
          pageSize
        };
        this.pagination.current = current
        this.getTableData(param);
      },
      async getTableData(options) {
        this.loading = true;
        const params = {
          pageNum: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...options
        };
        if (this.queryData.name) {
          params.name = this.queryData.name;
        }
        if (this.queryData.taxCode) {
          params.taxCode = this.queryData.taxCode;
        }
        if (this.queryData.mobile) {
          params.mobile = this.queryData.mobile;
        }
        if (this.queryData.type) {
          params.type = this.queryData.type;
        }
        if (String(this.queryData.status)) {
          params.status = this.queryData.status;
        }
  
        this.pagination = {
          ...this.pagination
        };
        const { code, data } = await api.shopkeeperManage.enterpriseList(params);
        if (code === SUCCESS_CODE) {
          const { dataList = [], pageNum, pageSize, total } = data;
          this.tableData = dataList;
          this.pagination = {
            ...this.pagination,
            current: pageNum,
            pageSize,
            total
          };
        }
        this.loading = false;
      },
      search() {
        this.pagination.current = 1
        this.getTableData();
      },
      handlePreview(url) {
        this.previewImage = url;
        this.previewVisible = true;
      },
    },
    mounted() {
      this.getTableData();
    }
  };
  </script>
  
  <style lang="less" scoped>
  .remark-wrap {
    width: 250px;
    white-space: normal;
    text-align: left;
  }
  .live-info-wrap{
    display: flex;
    height: 100px;
    .left{
      width: 100px;
      display: flex;
      align-items: center;
      img{
        width:80px;
        height:64px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: left;
    }
  }
  .img-wrapper {
      height: 150px;
      max-width: 100%;
      display: inline-block;
      position: relative;
      img {
        height: 100%;
        max-width: 100%;
      }
      .tool {
        visibility: hidden;
      }
      &:hover .tool {
        visibility: inherit;
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        transition: all .3s;
        background-color: rgba(0,0,0,.65);
        i {
          color: #e6e2e0;
          font-size: 18px;
          margin: 0 12px;
          cursor: pointer;
          &:hover {
              color: #fff;
          }
        }
      }
    }
    .show-img{
      display: flex;
      justify-content: center;
      cursor: pointer;
      div{
        width: 50px;
        height: 50px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .upload-tips{
      width:450px;
      margin-left: 138px;
      margin-top: -10px;
      font-size: 12px;
      color:#999;
      
    }
  </style>